import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import BackNavBar from '@src/components/backNavBar';
import BodyText from '@components/bodyText';
import Layout from '@components/layout';

const SekkiLan = ({ data }) => {
  const headLine = data.contentfulDescriptionComponent.headLine;
  const bodyText = data.contentfulDescriptionComponent.bodyText;

  return (
    <Layout>
      <SEO title={headLine} />
      <BackNavBar text="Tillbaka till SEKKI" back="/sekki"></BackNavBar>
      <div id="first">
        <BodyText bodyText={bodyText} />
      </div>
    </Layout>
  );
};

export default SekkiLan;

SekkiLan.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query SekkiNordiskaQuery {
    contentfulDescriptionComponent(
      id: { eq: "43c23fc9-fb5b-5173-bc41-63690ead6df1" }
    ) {
      id
      headline
      bodyText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
